import { parsePhoneNumber } from 'libphonenumber-js';
import ValidationError from '../errors/ValidationError';
import { FormData } from '../types/FormData';
import { TeamMember } from '../types/TeamMember';

class Api {
  base = '';

  constructor() {
    if (process.env.REACT_APP_API_BASE_URL) {
      this.base = process.env.REACT_APP_API_BASE_URL;
    }
  }

  submitLead(formData: FormData) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const phoneNumber = parsePhoneNumber(formData.phone, 'US');

    const formattedData = {
      ...formData,
      phone: phoneNumber.nationalNumber,
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
    };

    const body = JSON.stringify(formattedData);

    const requestOptions = {
      method: 'POST',
      headers,
      body,
    };

    return new Promise((resolve, reject) => {
      fetch(`${this.base}/lead-submission`, requestOptions)
        .then(async (response) => {
          const data = await response.json();

          if (!response.ok) {
            if (response.status === 400) {
              reject(new ValidationError(response, data));
              return;
            }

            reject(new Error('API request failed'));
            return;
          }

          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async getTeamMember(abbv: String): Promise<null | TeamMember> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const response = await fetch(`${this.base}/team-member/${abbv}`, requestOptions);

    if (response) {
      return response.json();
    }

    return null;
  }
}

export default new Api();
