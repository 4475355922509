import { SplitFactory } from '@splitsoftware/splitio';
import SplitIO from '@splitsoftware/splitio/types/splitio';

class Split {
  private client?: SplitIO.IClient;

  async getTreatmentWithConfig(featureName: string): Promise<SplitIO.TreatmentWithConfig> {
    const apiKey = process.env.REACT_APP_SPLIT_API_KEY;
    if (!apiKey) throw new Error('No split api key');
    const factory = SplitFactory({
      core: {
        authorizationKey: apiKey,
        key: 'referral-lead-form-ui',
      },
    });
    this.client = factory.client();
    await this.client.ready();
    return this.client.getTreatmentWithConfig(featureName);
  }

  async destrotySplitClient(): Promise<void> {
    this.client?.destroy();
  }
}

export default new Split();
