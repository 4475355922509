import { forwardRef } from 'react';
import useStyles from './Footer.styles';

export type Ref = null | HTMLSpanElement;

const OfferDetailsTwo = forwardRef<Ref>((_, ref) => {
  const { classes } = useStyles();

  return (
    <span ref={ref}>
      <sup className={classes.superscript}>2</sup>
    </span>
  );
});

export default OfferDetailsTwo;
