import { Route, Routes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { rktTheme } from '@rocketcentral/rocket-design-system-react';
import { CssBaseline } from '@mui/material';
import ThankYouPage from './components/ThankYouPage/ThankYouPage';
import LeadFormPage from './components/LeadFormPage';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={rktTheme}>
        <CssBaseline />
        <ScrollToTop />
        <Routes>
          <Route index element={<LeadFormPage />} />
          <Route path="success" element={<ThankYouPage />} />
          <Route path=":abbreviationId" element={<LeadFormPage />} />
        </Routes>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
