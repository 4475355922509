import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  header: {
    marginTop: '88px',
    marginBottom: '25px',
  },
  'header-content': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
