import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  'terms-of-service': {
    maxWidth: '440px',
    margin: '0 auto',
  },
  'terms-of-service-link': {
    color: 'inherit',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
  },
  'container-450': {
    maxWidth: '450px',
    margin: '0 auto',
  },
  'radio-group-item': {
    borderRadius: '12px',
  },
}));

export default useStyles;
