import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  supLink: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  'container-680': {
    maxWidth: '680px',
    margin: '0 auto',
  },
  'container-900': {
    maxWidth: '900px',
    margin: '0 auto',
  },
  'cursor-pointer': {
    cursor: 'pointer',
  },
}));

export default useStyles;
