import { Divider, Link, Stack, Box } from '@mui/material';
import { RefObject, useEffect, useState } from 'react';
import OfferDetailsHeader from './OfferDetailsHeader';
import OfferDetailsOne from './OfferDetailsOne';
import OfferDetailsTwo from './OfferDetailsTwo';
import OfferDetailsThree from './OfferDetailsThree';
import useStyles from './Footer.styles';
import Split from '../../services/Split';

function Footer({
  offerDetailsRef,
  offerDetailsOneRef,
  offerDetailsTwoRef,
  offerDetailsThreeRef,
}: {
  offerDetailsRef: RefObject<null | HTMLDivElement>;
  offerDetailsOneRef: RefObject<null | HTMLDivElement>;
  offerDetailsTwoRef: RefObject<null | HTMLDivElement>;
  offerDetailsThreeRef: RefObject<null | HTMLDivElement>;
}) {
  const [promotionDisclaimerText, setPromotionDisclaimerText] = useState();

  useEffect(() => {
    Split.getTreatmentWithConfig('Promotion')
      .then(({ treatment, config }) => {
        if (treatment === 'on' && config) {
          const promotionDetails = JSON.parse(config);
          setPromotionDisclaimerText(promotionDetails.disclaimerText);
        }
      })
      .catch();

    return () => {
      Split.destrotySplitClient();
    };
  }, []);

  const { classes } = useStyles();
  const brandingHeader = {
    src: '../rocket_branding_header.svg',
    alt: 'Rocket Logo',
  };
  return (
    <div className="rkt-GlobalFooter">
      <footer className="rkt-CenteredColumn rkt-pv4 rkt-ph7">
        <div className="rkt-GlobalFooter__brands-header rkt-mv6 rkt-pv3">
          <img
            className="rkt-GlobalFooter__brands-header-image rkt-ImgResponsive rkt-mb2"
            alt={brandingHeader.alt}
            src={brandingHeader.src}
          />
        </div>
        <Stack className="rkt-Stack rkt-Stack--split@5 rkt-mb8">
          <Stack
            direction={{ 0: 'column', 5: 'row' }}
            className="
            rkt-Stack rkt-Stack--split@5
            rkt-Stack__item
          "
          >
            <div className="rkt-Stack__item rkt-Stack__item--half@5">
              <h6 className="rkt-Heading-20 rkt-Color--white rkt-pb4">Rocket Mortgage</h6>
              <Stack
                rowGap={3}
                component="ul"
                className="rkt-ListStyle--none rkt-ml0 rkt-pl0 rkt-mt0 rkt-pr5"
              >
                <li>
                  <Link
                    href="https://www.rocketmortgage.com/legal/email-policy?qls=RFR_socrfrff.0123456789"
                    classes={{ root: 'rkt-Link rkt-Link--light' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Email and Text Policy
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.rocketaccount.com/#/privacy-policy?qls=RFR_socrfrff.0123456789"
                    classes={{ root: 'rkt-Link rkt-Link--light' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Security and Privacy
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.rocketaccount.com/#/do-not-sell-my-personal-information?qls=RFR_socrfrff.0123456789"
                    classes={{ root: 'rkt-Link rkt-Link--light' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Do Not Sell My Information
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.rocketmortgage.com/legal/disclosures-licenses?qls=RFR_socrfrff.0123456789"
                    classes={{ root: 'rkt-Link rkt-Link--light' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Disclosures and Licenses
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.rocketmortgage.com/legal/terms-of-use?qls=RFR_socrfrff.0123456789"
                    classes={{ root: 'rkt-Link rkt-Link--light' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </Link>
                </li>
              </Stack>
            </div>
            <Stack
              className="rkt-Stack__item rkt-Stack__item--half@5"
              sx={{
                flexDirection: { md: 'column' },
                alignItems: { md: 'center' },
                paddingTop: { xs: 2, md: 0 },
              }}
            >
              <div>
                <h6 className="rkt-Heading-20 rkt-Color--white rkt-pb4">Support and Feedback </h6>
                <Stack
                  rowGap={3}
                  component="ul"
                  className="rkt-ListStyle--none rkt-ml0 rkt-pl0 rkt-mt0"
                >
                  <li>
                    <Link
                      href="https://www.rocketmortgage.com/opt-out?qls=RFR_socrfrff.0123456789"
                      classes={{ root: 'rkt-Link rkt-Link--light' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Communication Opt-Out
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://surveys.rocketmortgage.com/jfe/form/SV_8rkw7oGYD5W6h3D"
                      classes={{ root: 'rkt-Link rkt-Link--light' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Give Us Your Feedback
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://www.rocketmortgage.com/site-accessibility?qls=RFR_socrfrff.0123456789"
                      classes={{ root: 'rkt-Link rkt-Link--light' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Site Accessibility
                    </Link>
                  </li>
                </Stack>
              </div>
            </Stack>
          </Stack>
        </Stack>
        <Divider classes={{ root: 'rkt-HorizontalDivider rkt-HorizontalDivider--dark' }} />
        <div className="rkt-mt2 rkt-pt7 rkt-mb8 rkt-pb3">
          <h6 className="rkt-Heading-20 rkt-Color--white rkt-mb3">Legal Information</h6>
          <p className="rkt-Caption-12 rkt-Color--white">
            NMLS #3030 &#45;{' '}
            <Link
              href="https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/3030?qls=RFR_socrfrff.0123456789"
              className="rkt-Caption-12 rkt-Color--white rkt-Link rkt-Link--light"
              target="_blank"
              rel="noreferrer"
            >
              NMLS consumer access page
            </Link>
          </p>
          <p className="rkt-Caption-12 rkt-Color--white rkt-mb3">
            Rocket Mortgage, 1050 Woodward Avenue, Detroit, MI 48226-1906.
          </p>
          <p className="rkt-Caption-12 rkt-Color--white">
            ©2000 - 2022 Rocket Mortgage. All rights reserved. Lending services provided by Rocket
            Mortgage, a subsidiary of Rocket Companies, Inc. (NYSE: RKT).
          </p>
        </div>

        <Divider classes={{ root: 'rkt-HorizontalDivider rkt-HorizontalDivider--dark' }} />

        <Stack className="rkt-Stack rkt-Stack--split@5 rkt-pv2 rkt-mv7">
          <Stack className="rkt-Stack__item rkt-Stack__item--half@5">
            <Box sx={{ marginLeft: { xs: '.625rem' }, marginRight: { md: '.75rem' } }}>
              <OfferDetailsHeader ref={offerDetailsRef} />
              <p className="rkt-Body-14 rkt-Color--white caption rkt-FontWeight--300">
                <OfferDetailsOne ref={offerDetailsOneRef} />
                Referral clients will receive a 0.375% rate reduction and a $1,500 lender-paid
                credit toward closing costs. One point is equal to one percent of the loan amount.
                This offer is only available to clients who lock their interest rate while
                completing the application process on or after February 21, 2023. This offer cannot
                be retroactively applied to previously closed loans or previously locked loans.
                Offer does not apply to new loans submitted through a mortgage broker, non-agency
                jumbo loans, non-QM loans, C-product loans (Schwab), portfolio loans, Rocket jumbo
                loans or team member loans. This offer is only available to clients who are referred
                by team members of Amrock LLC, Amrock Title Insurance Company, Bedrock Management
                Services LLC, Core Digital Media, Detroit Labs, Detroit Venture Partners,
                Dictionary.com, For Sale By Owner, Nexsys Technologies LLC, Rocket Mortgage, Rocket
                Community Fund, RKT Holdings LLC, Rocket Connections, Rocket Central, Rocket Solar,
                Rock FOC Technologies Canada ULC, Rocket Auto, Rocket Homes Real Estate LLC, Rocket
                Money, RockLoans Marketplace LLC, Rock Executive Security LLC, Rock Security LLC,
                Rock Ventures, Sift, Woodward Capital Management LLC, and Woodward Original,
                including interns, and who have been submitted through the Rocket Mortgage internal
                referral site, applied through a team member&apos;s referral link or through contact
                from the Referral Channel due to direct referral from an eligible team member.
                Rocket Mortgage reserves the right to cancel this offer at any time. Acceptance of
                this offer constitutes acceptance of these terms and conditions, which are subject
                to change at the sole discretion of Rocket Mortgage. Offer is nontransferable. This
                is not a commitment to lend. Additional restrictions/conditions may apply. Offer not
                valid with any additional discounts or promotions.
              </p>
              <p className="rkt-Body-14 rkt-Color--white rkt-pt4 caption rkt-FontWeight--300">
                <OfferDetailsTwo ref={offerDetailsTwoRef} />
                The following offers are available only to clients who are purchasing a home and
                financing with Rocket Mortgage: (1) Client purchasing with a Rocket Homes Partner
                Agent through Rocket Homes will receive a lender paid credit that is 1.25% of their
                loan amount. For example, a $200,000 home purchase price minus a 5% down payment of
                $10,000 results in a loan amount of $190,000. Client would receive a credit of 1.25%
                of the $190,000, for a credit of $2,375 toward closing costs. (2) Client is referred
                by a real estate agent to buy a new home will receive a lender paid credit that is
                0.50% of their loan amount. Offer does not apply to new loans submitted through an
                Executive Loan Officer, mortgage broker, through Schwab, or on nonagency jumbo
                loans, non-QM loans, bank statement loans, second lien products or team member
                loans. Offer may not be redeemed for cash and is nontransferable. Offer cannot be
                retroactively applied to loans where the rate has been locked. Conditions and
                exclusions apply. This is not a commitment to lend and is contingent on
                qualification per full underwriting guidelines.
              </p>
              <p className="rkt-Body-14 rkt-Color--white rkt-pt4 caption rkt-FontWeight--300">
                <OfferDetailsThree ref={offerDetailsThreeRef} />
                Qualifying for Rocket Homes rebate requires clients selling their home using a real
                estate agent that participates in Rocket Homes&apos; Partner Network. Upon closing,
                the Rocket Homes Network Partner pays a referral fee to Rocket Homes. Rocket Homes
                will rebate up to 0.5% of the final sale price to the client. Clients retain the
                right to negotiate their individual listing agreement. All Agents have been
                independently vetted by Rocket Homes to meet performance expectations required to
                participate in the program. If clients are currently working with a real estate
                agent, this is not a solicitation. Consumers who have engaged an agent not in the
                Rocket Homes Partner Network are not eligible for Rocket Homes rebate. Rocket Homes
                rebate is not available in Alabama, Alaska, Iowa, Kansas, Mississippi, Missouri, New
                Jersey, Oklahoma, Oregon and Tennessee. Rocket Homes rebate checks will be issued
                within 30 days of Rocket Homes receiving settlement statements and any other
                documentation reasonably required to calculate the applicable Rebate amount. Rocket
                Homes rebate is offered by Rocket Homes Real Estate LLC, a licensed real estate
                broker. Rocket Homes rebate is not available when the final sale price of the home
                is less than $100,000.00. Offer cannot be combined with any other Rocket Homes
                rebate or incentive. Additional restrictions/conditions may apply. Offer subject to
                change or cancellation without notice.
              </p>
            </Box>
          </Stack>
          <Stack className="rkt-Stack__item rkt-Stack__item--half@5">
            <Box sx={{ paddingTop: { xs: 4, md: 0 }, marginLeft: { md: '.75rem' } }}>
              <h6 className="rkt-Body-14 rkt-pb4 rkt-Color--white caption">
                Detalles De La Oferta
              </h6>
            </Box>
            <Box sx={{ marginLeft: { xs: '.625rem', md: '1.375rem' } }}>
              <p className="rkt-Body-14 rkt-Color--white caption rkt-FontWeight--300">
                <sup className={classes.superscript}>1</sup>
                Los clientes recomendados recibirán una reducción de la tasa del 0.375% y un crédito
                pagado por el prestamista de $1,500 para los costos de cierre. Un punto es igual al
                uno por ciento de la cantidad del préstamo. Esta oferta solo está disponible para
                los clientes que congelen su tasa de interés mientras completan el proceso de
                solicitud el 21 de febrero de 2023 o después. Esta oferta no se puede aplicar de
                manera retroactiva a préstamos cerrados o congelados antes de esa fecha. La oferta
                no se aplica a préstamos nuevos que se presenten mediante un corredor hipotecario,
                préstamos jumbo que no sean de una agencia, préstamos que no sean QM, préstamos de
                producto C (Schwab), préstamos de cartera, préstamos jumbo de Rocket o préstamos
                para miembros del equipo. Esta oferta solo está disponible para clientes
                recomendados por miembros del equipo de Amber Engine, Amrock LLC, Amrock Title
                Insurance Company, Bedrock Management Services LLC, Cleveland Cavaliers, Core
                Digital Media, Detroit Labs, Detroit Venture Partners, Dictionary.com, Nexsys
                Technologies LLC, RKT Holdings LLC, Rock Connections, Rock Executive Security LLC,
                Rock FOC Technologies Canada ULC, Rock Security LLC, Rock Ventures, Rocket Auto,
                Rocket Central, Rocket Community Fund, Rocket Homes Real Estate LLC, Rocket
                Mortgage, Rocket Solar, RockLoans Marketplace LLC, Sift, Woodward Capital Management
                LLC y Woodward Original, incluyendo los pasantes, y que hayan presentado una
                solicitud por medio del sitio interno de recomendaciones de Rocket Mortgage, por
                medio del enlace de recomendaciones de un miembro del equipo o por medio del
                contacto del Canal de Recomendaciones por recomendación directa de un miembro del
                equipo elegible. Rocket Mortgage se reserva el derecho de cancelar esta oferta en
                cualquier momento. La aceptación de esta oferta implica la aceptación de estos
                términos y condiciones, que están sujetos a cambios a criterio exclusivo de Rocket
                Mortgage. La oferta no es transferible. Esto no es un compromiso de préstamo. Puede
                haber otras restricciones o condiciones. La oferta no es válida con ningún otro
                descuento ni promoción.
              </p>
              <p className="rkt-Body-14 rkt-Color--white rkt-pt4 caption rkt-FontWeight--300">
                <sup className={classes.superscript}>2</sup>
                Las siguientes ofertas están disponibles solo para los clientes que compren una
                vivienda con financiamiento de Rocket Mortgage: (1) Un cliente que compre con un
                agente asociado de Rocket Homes por medio de Rocket Homes recibirá un crédito pagado
                por el prestamista del 1.25 % de la cantidad del préstamo. Por ejemplo, el precio de
                compra de $200,000 de una vivienda menos un pago inicial del 5% de $10,000, da como
                resultado una cantidad del préstamo de $190,000. El cliente recibiría un crédito del
                1.25% de los $190,000, es decir, $2,375 para pagar los costos de cierre. (2) Un
                cliente remitido por un agente de bienes raíces para comprar una casa nueva recibirá
                un crédito pagado por el prestamista del 0.50% de la cantidad del préstamo. La
                oferta no se aplica a los préstamos nuevos presentados mediante un agente de
                préstamos ejecutivo, un corredor hipotecario, por medio de Schwab, ni en los
                préstamos jumbo que no son de la agencia, préstamos que no son de QM, préstamos
                sobre estados de cuenta, productos con segundo gravamen ni préstamos de miembros del
                equipo. La oferta no se puede canjear por dinero en efectivo y no es transferible.
                La oferta no se puede aplicar retroactivamente a los préstamos en los que la tasa se
                haya bloqueado. Se aplican condiciones y exclusiones. Esto no es un compromiso de
                préstamo y depende de la calificación según las pautas para la evaluación de riesgo.
              </p>
              <p className="rkt-Body-14 rkt-Color--white rkt-pt4 caption rkt-FontWeight--300">
                <sup className={classes.superscript}>3</sup>
                Para calificar para el descuento de Rocket Homes, los clientes deben vender su casa
                con un agente de bienes raíces que participe en la Red de Socios de Rocket Homes. En
                el momento del cierre, el socio de la Red Rocket Homes le paga un cargo por
                recomendación a Rocket Homes. Rocket Homes le dará al cliente hasta un 0.5% de
                descuento sobre el precio final de venta. Los clientes mantienen su derecho a
                negociar su contrato individual de listado. Rocket Homes ha aprobado
                independientemente a todos los agentes que cumplen las expectativas de desempeño
                necesarias para participar en el programa. Si los clientes están trabajando están
                trabajando actualmente con un agente de bienes raíces, esto no es una propuesta
                propuesta comercial. Los consumidores que hayan contratado a un agente que no
                pertenezca a la Red de Socios Rocket Homes no serán elegibles para el descuento de
                Rocket Homes. El descuento de Rocket Homes no está disponible en Alabama, Alaska,
                Iowa, Kansas, Misisipi, Misuri, Nueva Jersey, Oklahoma, Oregón ni Tennessee. Los
                cheques de descuento de Rocket Homes se emitirán en un plazo de 30 días desde que
                Rocket Homes reciba las declaraciones de cierre y cualquier otra documentación que
                se requiera razonablemente para calcular la cantidad del descuento correspondiente.
                El descuento de Rocket Homes se ofrece mediante Rocket Homes Real Estate LLC, un
                corredor inmobiliario autorizado. El descuento de Rocket Homes no está disponible si
                el precio de venta final de la vivienda es de menos de $100,000.00. La oferta no
                puede combinarse con ningún otro descuento ni incentivo de Rocket Homes. Puede haber
                otras restricciones/condiciones. La oferta está sujeta a modificaciones o a la
                cancelación sin previo aviso.
              </p>
            </Box>
          </Stack>
        </Stack>

        {promotionDisclaimerText && (
          <p
            className="rkt-Body-14 rkt-Color--white caption rkt-FontWeight--300"
            style={{ marginBottom: '20px' }}
          >
            {promotionDisclaimerText}
          </p>
        )}

        <Divider classes={{ root: 'rkt-HorizontalDivider rkt-HorizontalDivider--dark' }} />

        <Stack className="rkt-Stack rkt-Stack--split@5 rkt-pt2 rkt-mt7">
          <Stack className="rkt-Stack__item rkt-Stack__item--half@5">
            <p className="rkt-Body-14 rkt-Color--white caption rkt-FontWeight--300">
              Rocket Mortgage, LLC, Rocket Homes Real Estate LLC, RockLoans Marketplace LLC (doing
              business as Rocket Loans), Rocket Auto LLC, Rocket Solar, LLC and Rocket Money are
              separate operating subsidiaries of Rocket Companies, Inc. (NYSE: RKT). Each company is
              a separate legal entity operated and managed through its own management and governance
              structure as required by its state of incorporation and applicable legal and
              regulatory requirements.
            </p>
          </Stack>
          <Stack className="rkt-Stack__item rkt-Stack__item--half@5" pt={{ xs: 4, md: 0 }}>
            <Box sx={{ ml: { md: '1.5rem' } }}>
              <p className="rkt-Body-14 rkt-Color--white caption rkt-FontWeight--300">
                Rocket Mortgage, LLC, Rocket Homes Real Estate LLC, RockLoans Marketplace LLC (que
                también opera como Rocket Loans), Rocket Auto LLC, Rocket Solar, LLC y Rocket Money
                son subsidiarias independientes de Rocket Companies, Inc. (NYSE: RKT). Cada compañía
                es una entidad legal separada, operada y administrada por su propia estructura de
                dirección y administración, según lo exige el estado donde está constituida y los
                requisitos legales y normativos aplicables.
              </p>
            </Box>
          </Stack>
        </Stack>
      </footer>
    </div>
  );
}

export default Footer;
