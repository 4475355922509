import '../../styles/App.scss';
import { useEffect, useRef } from 'react';
import { Stack, Box } from '@mui/material';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

function ThankYouPage() {
  const offerDetailsRef = useRef(null);
  const offerDetailsOneRef = useRef(null);
  const offerDetailsTwoRef = useRef(null);
  const offerDetailsThreeRef = useRef(null);

  useEffect(() => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: 'FORM_SUCCESS',
      metadata: {
        form_name: 'rocket refer lead form',
        type: 'mortgage referral',
      },
    });
  }, []);

  return (
    <>
      <Header />
      <main>
        <Stack className="rkt-TextAlign--center" pb="11rem" px={{ xs: 4, md: 8 }}>
          <h1 className="rkt-Heading-36">We&apos;ve received your information, thank you!</h1>
          <Box maxWidth="720px" margin="0 auto">
            <p className="rkt-Body-16 rkt-pt4">
              A Rocket Mortgage® loan officer will contact you in the next 24 business hours, Monday
              through Friday.
            </p>
          </Box>
        </Stack>
      </main>
      <Footer
        offerDetailsRef={offerDetailsRef}
        offerDetailsOneRef={offerDetailsOneRef}
        offerDetailsTwoRef={offerDetailsTwoRef}
        offerDetailsThreeRef={offerDetailsThreeRef}
      />
    </>
  );
}

export default ThankYouPage;
