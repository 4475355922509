import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { NOT_FOUND_REDIRECT_URL, LOCAL_REDIRECT_URL } from './consts';
import reportWebVitals from './reportWebVitals';

if (window.location.pathname === '/') {
  if (process.env.NODE_ENV === 'development') {
    window.location.replace(LOCAL_REDIRECT_URL);
  } else {
    window.location.replace(NOT_FOUND_REDIRECT_URL);
  }
} else {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
