import { Box } from '@mui/material';
import logo from '../../images/rocket-mortgage.png';
import useStyles from './Header.styles';

function Header() {
  const { classes } = useStyles();

  return (
    <header className={classes.header}>
      <Box className={classes['header-content']}>
        <img src={logo} height="28px" alt="Rocket Mortgage" />
      </Box>
    </header>
  );
}

export default Header;
