class ValidationError extends Error {
  response: Response;

  errors: {};

  constructor(response: Response, data: { errors: {} }, ...params: any) {
    super(...params);

    this.response = response;
    this.errors = data.errors;
  }
}

export default ValidationError;
