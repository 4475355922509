import { rktTheme } from '@rocketcentral/rocket-design-system-react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  superscript: {
    marginLeft: '-0.625rem',
    marginRight: '0.25rem',
  },
  caption: {
    [rktTheme.breakpoints.down(768)]: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
  },
}));

export default useStyles;
