import { Divider, Stack, Link } from '@mui/material';
import { FormEvent, RefObject } from 'react';
import { TeamMember } from '../../types/TeamMember';
import useStyles from './OfferSummary.styles';

function OfferSummary({
  teamMember,
  offerDetailsRef,
  offerDetailsOneRef,
  offerDetailsTwoRef,
  offerDetailsThreeRef,
}: {
  teamMember: TeamMember | null;
  offerDetailsRef: RefObject<null | HTMLDivElement>;
  offerDetailsOneRef: RefObject<null | HTMLDivElement>;
  offerDetailsTwoRef: RefObject<null | HTMLDivElement>;
  offerDetailsThreeRef: RefObject<null | HTMLDivElement>;
}) {
  const { classes } = useStyles();
  const scrollToOfferDetails = (e: FormEvent) => {
    e.preventDefault();

    if (offerDetailsRef?.current) {
      offerDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToOfferDetailsOne = (e: FormEvent) => {
    e.preventDefault();

    if (offerDetailsOneRef?.current) {
      offerDetailsOneRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToOfferDetailsTwo = (e: FormEvent) => {
    e.preventDefault();

    if (offerDetailsTwoRef?.current) {
      offerDetailsTwoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToOfferDetailsThree = (e: FormEvent) => {
    e.preventDefault();

    if (offerDetailsThreeRef?.current) {
      offerDetailsThreeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`${classes['container-900']} rkt-TextAlign--center`}>
      <div className="rkt-mb4">
        {teamMember?.firstName && teamMember?.lastInitial && (
          <h1 className="rkt-Heading-36 rkt-Heading-36--responsive">
            Great news! You&apos;re eligible for special mortgage loan savings
            <span
              onClick={scrollToOfferDetailsOne}
              onKeyDown={scrollToOfferDetailsOne}
              className="supLink rkt-Color--black"
              role="button"
              tabIndex={-2}
            >
              ¹
            </span>{' '}
            because {teamMember?.firstName} {teamMember?.lastInitial}. referred you.
          </h1>
        )}
        {!teamMember?.firstName && !teamMember?.lastInitial && (
          <h1 className="rkt-Heading-36 rkt-Heading-36--responsive">
            Great news! You&apos;re eligible for special mortgage loan savings
            <span
              onClick={scrollToOfferDetailsOne}
              onKeyDown={scrollToOfferDetailsOne}
              className={`${classes.supLink} rkt-Color--black`}
              role="button"
              tabIndex={-2}
            >
              ¹
            </span>{' '}
            because you were referred.
          </h1>
        )}
      </div>
      <div className={`${classes['container-680']}`}>
        <Stack spacing={4}>
          <p className="rkt-Body-16">
            Buy
            <span
              onClick={scrollToOfferDetailsTwo}
              onKeyDown={scrollToOfferDetailsTwo}
              className="supLink rkt-Color--black"
              role="button"
              tabIndex={-1}
            >
              ²
            </span>{' '}
            with Rocket Homes℠ and Rocket Mortgage® and you could get a 1.25% closing credit from
            Rocket Mortgage, up to $10,000.{' '}
          </p>
          <p className="rkt-Body-16">
            Sell
            <span
              onClick={scrollToOfferDetailsThree}
              onKeyDown={scrollToOfferDetailsThree}
              className="supLink rkt-Color--black"
              role="button"
              tabIndex={0}
            >
              ³
            </span>{' '}
            with Rocket Homes and get a 0.5% rebate.
          </p>
          <p className="rkt-Body-16">Do both, get both!</p>
          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={scrollToOfferDetails}
              className="cursor-pointer offer-details-link rkt-Link"
              color="secondary"
            >
              View Offer Details
            </Link>
          </div>
          <p className="rkt-Body-16">
            Submit your information below. Then, we&apos;ll connect you to a mortgage loan officer
            today.
          </p>
        </Stack>
      </div>
      <Divider classes={{ root: 'rkt-HorizontalDivider rkt-mv5' }} />
    </div>
  );
}

export default OfferSummary;
